<script>

export default {
  name: 'Dashboard',
  props: {
    planoAtual: {required: true}
  },
  data () {
    return {
      ativo: 'tMensal'
    }
  },
  computed: {
  },
  methods: {
    alternarPlano(){
      this.ativo = this.ativo === 'tMensal' ? 'tAnual' : 'tMensal';
    }
  },
  meta: {
    title: 'Planos',
    name: 'Planos'
  }
}
</script>

<template>
  <section class="cont-planos" id="cont-planos">
    <div class="plano-h">
    <h2>Nossos <strong>planos</strong></h2>

    <div class="planoSelect">
      <div @click="alternarPlano" :class="['toggle', ativo]" class="contTypes">
        <span class="c-left">Mensal</span>
        <span class="c-right">Anual</span>
      </div>

      <strong>Economize 10%</strong>
    </div>
    </div>

    <div class="listPlanos" :class="['toggle', ativo]">
      <article class="plano-unico plano-simplificado">
        <div class="h-plano">
          <h3>Simplificado</h3>

          <div class="c-row">
            <div class="c-left c-mensal">
              <small>R$</small>
              <strong>499</strong>
              <span>/mês</span>
            </div>

            <div class="c-left c-anual">
              <small>R$</small>
              <strong>440</strong>
              <span>/anual</span>
            </div>

            <div class="c-right c-isento">
              <small>Setup:</small>
              <strong>Isento</strong>
            </div>
          </div>
        </div>

        <div class="b-plano">
          <div class="cont-desc">
            <a class="show-modal disabled" v-if="planoAtual.slug === 'simplificado'">Plano atual</a>
            <a class="show-modal bg-primary hide" v-else>Mudar para este plano</a>
            <p>Utilize a <strong>versão simplificada</strong> e reduzida do nosso software e utilize somente para realizar seus leilões.</p>
          </div>

          <ul class="list-itens">
            <li><i class="far fa-times"></i>Versão sem ERP e seus módulos</li>
            <li><i class="far fa-check"></i>Website</li>
            <li><i class="far fa-check"></i>Módulo de Leilões e Lotes</li>
            <li><i class="far fa-check"></i>Módulo de Arrematantes</li>
            <li><i class="far fa-check"></i>Cadastro de Comitentes e relatórios básicos</li>
          </ul>

          <ul class="f-plano">
            <li>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15"><g transform="translate(0 -64)"><path d="M0,112v10.767A2.519,2.519,0,0,0,2.5,125.3h15a2.519,2.519,0,0,0,2.5-2.533V112a1.91,1.91,0,0,1-.75,1.52l-8.5,6.46a1.241,1.241,0,0,1-1.5,0l-8.5-6.46A1.91,1.91,0,0,1,0,112Z" transform="translate(0 -46.3)" opacity="0.4"></path><path d="M1.875,64A1.794,1.794,0,0,0,0,65.7a1.653,1.653,0,0,0,.75,1.36l8.5,5.78a1.359,1.359,0,0,0,1.5,0l8.5-5.78A1.653,1.653,0,0,0,20,65.7,1.794,1.794,0,0,0,18.125,64Z"></path></g></svg>Suporte via helpdesk
            </li>

            <li>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M17,34.906a9.914,9.914,0,0,0-15.6,11.96L0,52l5.254-1.379a9.881,9.881,0,0,0,4.737,1.205h0A9.848,9.848,0,0,0,17,34.906ZM10,50.156a8.223,8.223,0,0,1-4.2-1.147l-.3-.179-3.116.817.83-3.04-.2-.312a8.253,8.253,0,1,1,15.308-4.379A8.329,8.329,0,0,1,10,50.156Zm4.518-6.17c-.246-.125-1.464-.723-1.692-.8s-.393-.125-.558.125-.638.8-.786.973-.29.188-.536.063A6.741,6.741,0,0,1,7.571,41.4c-.254-.437.254-.406.728-1.353a.459.459,0,0,0-.022-.433c-.062-.125-.558-1.344-.763-1.839s-.406-.415-.558-.424-.308-.009-.473-.009a.917.917,0,0,0-.661.308,2.783,2.783,0,0,0-.866,2.067,4.851,4.851,0,0,0,1.009,2.563A11.066,11.066,0,0,0,10.2,46.018a4.846,4.846,0,0,0,2.973.621,2.536,2.536,0,0,0,1.67-1.179,2.071,2.071,0,0,0,.143-1.179C14.924,44.17,14.759,44.107,14.513,43.987Z" transform="translate(0 -32)"></path></svg>Suporte via whatsapp limitado
            </li>
          </ul>
        </div>
      </article><!-- END Único -->

      <article class="plano-unico plano-premium">
        <div class="h-plano">
          <h3>Premium</h3>

          <div class="c-row">
            <div class="c-left c-mensal">
              <small>R$</small>
              <strong>1.500</strong>
              <span>/mês</span>
            </div>

            <div class="c-left c-anual">
              <small>R$</small>
              <strong>1.350</strong>
              <span>/anual</span>
            </div>

            <div class="c-right">
              <small>Setup:</small>
              <strong>R$ 5.000,00</strong>
            </div>
          </div>
        </div>

        <div class="b-plano">
          <div class="cont-desc">
            <a class="show-modal disabled" v-if="planoAtual.slug === 'premium' || planoAtual.slug === 'premium-codigo'">Plano atual</a>
            <a class="show-modal bg-primary hide" v-else>Mudar para este plano</a>
            <p>Utilize a <strong>versão completa</strong> do nosso software, incluindo nosso poderoso ERP e todos os módulos.</p>
          </div>

          <ul class="list-itens">
            <li><i class="far fa-check"></i>Website</li>
            <li><i class="far fa-check"></i>Módulo de Leilões e Lotes</li>
            <li><i class="far fa-check"></i>Módulo de Arrematantes</li>
            <li><i class="far fa-check"></i>Gestão de Pessoas</li>
            <li><i class="far fa-check"></i>Gestão de Processos</li>
            <li><i class="far fa-check"></i>Tarefas</li>
            <li><i class="far fa-check"></i>Gestão de Documentos</li>
            <li><i class="far fa-check"></i>Módulo Bens</li>
            <li><i class="far fa-check"></i>Módulo Comitentes e apps</li>
            <li><i class="far fa-check"></i>Módulo Financeiro</li>
            <li><i class="far fa-check"></i>CRM e Marketing</li>
          </ul>

          <ul class="f-plano">
            <li>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="15" viewBox="0 0 20 15"><g transform="translate(0 -64)"><path d="M0,112v10.767A2.519,2.519,0,0,0,2.5,125.3h15a2.519,2.519,0,0,0,2.5-2.533V112a1.91,1.91,0,0,1-.75,1.52l-8.5,6.46a1.241,1.241,0,0,1-1.5,0l-8.5-6.46A1.91,1.91,0,0,1,0,112Z" transform="translate(0 -46.3)" opacity="0.4"></path><path d="M1.875,64A1.794,1.794,0,0,0,0,65.7a1.653,1.653,0,0,0,.75,1.36l8.5,5.78a1.359,1.359,0,0,0,1.5,0l8.5-5.78A1.653,1.653,0,0,0,20,65.7,1.794,1.794,0,0,0,18.125,64Z"></path></g></svg>Suporte via helpdesk
            </li>

            <li>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M17,34.906a9.914,9.914,0,0,0-15.6,11.96L0,52l5.254-1.379a9.881,9.881,0,0,0,4.737,1.205h0A9.848,9.848,0,0,0,17,34.906ZM10,50.156a8.223,8.223,0,0,1-4.2-1.147l-.3-.179-3.116.817.83-3.04-.2-.312a8.253,8.253,0,1,1,15.308-4.379A8.329,8.329,0,0,1,10,50.156Zm4.518-6.17c-.246-.125-1.464-.723-1.692-.8s-.393-.125-.558.125-.638.8-.786.973-.29.188-.536.063A6.741,6.741,0,0,1,7.571,41.4c-.254-.437.254-.406.728-1.353a.459.459,0,0,0-.022-.433c-.062-.125-.558-1.344-.763-1.839s-.406-.415-.558-.424-.308-.009-.473-.009a.917.917,0,0,0-.661.308,2.783,2.783,0,0,0-.866,2.067,4.851,4.851,0,0,0,1.009,2.563A11.066,11.066,0,0,0,10.2,46.018a4.846,4.846,0,0,0,2.973.621,2.536,2.536,0,0,0,1.67-1.179,2.071,2.071,0,0,0,.143-1.179C14.924,44.17,14.759,44.107,14.513,43.987Z" transform="translate(0 -32)"></path></svg>Suporte via whatsapp limitado
            </li>
          </ul>
        </div>
      </article><!-- END Único -->

      <article class="plano-unico plano-enterprise">
        <div class="h-plano">
          <h3>Enterprise</h3>

          <div class="c-row">
            <div class="c-left c-mensal">
              <small>R$</small>
              <strong>5.000</strong>
              <span>/mês</span>
            </div>

            <div class="c-left c-anual">
              <small>R$</small>
              <strong>4.500</strong>
              <span>/anual</span>
            </div>

            <div class="c-right">
              <small>Setup:</small>
              <strong>R$ 10.000,00</strong>
            </div>
          </div>
        </div>

        <div class="b-plano">
          <div class="cont-desc">
            <a class="show-modal disabled" v-if="planoAtual.slug === 'enterprise'">Plano atual</a>
            <a class="show-modal bg-primary hide" v-else>Mudar para este plano</a>
            <p>Utilize a <strong>versão completa</strong> do nosso software, incluindo nosso poderoso ERP e todos os módulos, além de <strong>um desenvolvedor exclusivo e nossa consultoria completa, com contado direto com nosso CEO.</strong></p>
          </div>

          <ul class="list-itens">
            <li><i class="far fa-check"></i>Todos os módulos do plano Premium</li>
            <li><i class="far fa-check"></i>Um desenvolvedor exclusivo sem limites de horas mensais</li>
            <li><i class="far fa-check"></i>Consultoria exclusiva para suporte e coordenação das melhorias, além de trabalhos de inteligência em dados com foco na performance dos leilões e vendas</li>
            <li><i class="far fa-check"></i>Trabalhos de SEO mensais</li>
            <li><i class="far fa-check"></i>Consultoria de marketing e estratégias</li>
            <li><i class="far fa-check"></i>Customização dos templates de e-mail marketing periódicos + criação de 1 campanha mensal</li>
            <li><i class="far fa-check"></i>Anúncios Google, Instagram e Facebook</li>
          </ul>

          <ul class="f-plano">
            <li>
              <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20"><path d="M17,34.906a9.914,9.914,0,0,0-15.6,11.96L0,52l5.254-1.379a9.881,9.881,0,0,0,4.737,1.205h0A9.848,9.848,0,0,0,17,34.906ZM10,50.156a8.223,8.223,0,0,1-4.2-1.147l-.3-.179-3.116.817.83-3.04-.2-.312a8.253,8.253,0,1,1,15.308-4.379A8.329,8.329,0,0,1,10,50.156Zm4.518-6.17c-.246-.125-1.464-.723-1.692-.8s-.393-.125-.558.125-.638.8-.786.973-.29.188-.536.063A6.741,6.741,0,0,1,7.571,41.4c-.254-.437.254-.406.728-1.353a.459.459,0,0,0-.022-.433c-.062-.125-.558-1.344-.763-1.839s-.406-.415-.558-.424-.308-.009-.473-.009a.917.917,0,0,0-.661.308,2.783,2.783,0,0,0-.866,2.067,4.851,4.851,0,0,0,1.009,2.563A11.066,11.066,0,0,0,10.2,46.018a4.846,4.846,0,0,0,2.973.621,2.536,2.536,0,0,0,1.67-1.179,2.071,2.071,0,0,0,.143-1.179C14.924,44.17,14.759,44.107,14.513,43.987Z" transform="translate(0 -32)"></path></svg>Suporte via whatsapp limitado
            </li>
          </ul>
        </div>
      </article><!-- END Único -->

      <article class="p-unify plano-codigo-fonte">
        <h4>Código-Fonte</h4>

        <h3>Consulte-nos</h3>

        <p>Utilize o software em nuvem, na infraestrutura <strong>dedicada</strong> da Suporte Leilões ou de sua escolha, sem limitações, com disponibilização do código fonte e <strong>direitos de uso e customização do software</strong>. O leiloeiro passa ser proprietário do código fonte e poderá usar o software mesmo sem a necessidade de contrato ativo com a Suporte Leilões.</p>

        <ul>
          <li>Software sem limitações</li>

          <li>Suporte exclusivo</li>

          <li>Receba atualizações sem custos adicionais enquanto o contrato de suporte estiver ativo.</li>
        </ul>

        <a href="https://www.suporteleiloes.com.br/atendimento" target="_blank" class="a-especialista show-modal">Fale com um especialista</a>
      </article><!-- END Único -->
    </div>
  </section>
</template>

<style src="./assets/default.styl" lang="stylus"></style>
