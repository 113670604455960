import {listTiposArquivo, deleteTipoArquivo} from '@/domain/cadastro/services'
import window from './window/window.js'

const permissoes = [
  {
    label: 'Público',
    value: 0
  },
  {
    label: 'Arrematante',
    value: 1
  },
  {
    label: 'Comitentes',
    value: 2
  },
  {
    label: 'Interno',
    value: 100
  }
]

export default {
  pageTitle: 'Tipos de Arquivo',
  columns: [
    {
      name: 'nome',
      required: true,
      label: 'Nome',
      align: 'left',
      field: 'nome',
      sortable: true,
      parser: function (props) {
        return props.nome
      }
    },
    {
      name: 'app',
      required: true,
      label: 'App Vistoria',
      align: 'left',
      field: 'app',
      sortable: true,
      parser: function (props) {
        return props.app ? 'Sim' : 'Não'
      }
    },
    {
      name: 'permissao',
      required: true,
      label: 'Permissão',
      align: 'left',
      field: 'permissao',
      sortable: true,
      parser: function (props) {
        const f = permissoes.find(p => p.value === props.permissao)
        return f ? f.label : f
      }
    },
    {
      name: 'active',
      required: true,
      label: 'Situação',
      align: 'left',
      field: 'active',
      sortable: true,
      parser: function (props) {
        return props.active ? 'Ativo' : 'Inativo'
      }
    },
    {
      name: 'options',
      required: true,
      label: '',
      field: 'options',
      sortable: false
    }
  ],
  resolve: listTiposArquivo,
  methods: {
    // window: window
    abrir: function (id) {
      window.call(this, {id: id, table: this.table})
    },
    novo: function () {
      window.call(this, {})
    },
    excluir: deleteTipoArquivo
  }
}
